<template>
  <PreviewEditablePeople
    v-model:open="open"
    :label="label"
    :selected-values="selectedValues"
    :trigger-class="triggerClass"
    v-bind="$attrs"
    :field-id="fieldId"
    :disabled="disabled"
    @select="onSelect"
    @remove="onRemove"
    @update:open="$emit('update:open', $event)"
  >
    <div :class="['pl-2 flex items-center z-10 h-full', { 'pr-8': !preview }]">
      <div
        v-if="selectedValues?.length"
        class="flex items-center"
      >
        <UTooltip
          v-for="(people, index) in selectedPeople.slice(0, 4)"
          :key="people.id"
          :class="[{ '-ml-1': index !==0 }]"
          :style="{ zIndex: (5-index)*2 }"
          :text="people.fullName"
          :popper="{ placement: 'top' }"
        >
          <Avatar
            :id="people.id"
            :src="people.photo"
            :name="people.fullName"
            size="xs"
            rounded="ring-1 ring-white rounded-full"
          />
        </UTooltip>
        <div v-if="selectedPeople.length > 4" class="ml-0.5 text-2xs">
          {{ `+${selectedPeople.length - 4}` }}
        </div>
      </div>
      <div
        v-else
        class="leading-4 text-xs group-hover/cell:visible invisible"
      >
        -
      </div>
      <div
        v-if="!preview"
        class="absolute right-1 top-1/2 -translate-y-1/2 flex items-center"
      >
        <UButton
          v-if="selectedValues.length && !disabled"
          color="gray"
          variant="ghost"
          size="2xs"
          icon="i-heroicons-x-mark"
          class="group-hover/cell:visible invisible"
          @click.prevent.stop="onClear"
        />
        <Icon
          v-else
          name="heroicons:chevron-down"
          class="text-gray-500 group-hover/cell:visible invisible mr-1"
          size="2xs"
        />
      </div>
    </div>
  </PreviewEditablePeople>
</template>

<script lang="ts" setup>
import type { TaskFieldRef } from '#field/types'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  boardId: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    default: undefined,
  },
  preview: {
    type: Boolean,
    default: false,
  },
  fieldId: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  triggerClass: {
    type: Function as PropType<(active: boolean) => string[]>,
  },
})

const { boardData } = useBoardSharedState()
const { updateTaskCustomField } = useUpdateTask()

const selectedValues = computed<string[]>(() => {
  return safeParseStringToJSON<string[]>(props.value ?? '', [])
})

defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'select', value: string, fieldId?: string): void
  (e: 'remove', value: string, fieldId?: string): void
}>()

const open = ref(false)

const selectedPeople = computed(() => {
  return boardData.value?.users
    .filter((user) => selectedValues.value.includes(user.id))
    .sort((a, b) => selectedValues.value.indexOf(a.id) - selectedValues.value.indexOf(b.id))
})

const onSelect = (value: string) => {
  updateTaskCustomField({
    taskIds: [props.taskId],
    fieldId: props.fieldId,
    value: null,
    addValueIds: [value],
    useSelectedTasks: true,
  })
}

const onRemove = (value: string) => {
  updateTaskCustomField({
    taskIds: [props.taskId],
    fieldId: props.fieldId,
    value: null,
    removeValueIds: [value],
    useSelectedTasks: true,
  })
}

const onClear = () => {
  updateTaskCustomField({
    taskIds: [props.taskId],
    fieldId: props.fieldId,
    value: null,
    useSelectedTasks: true,
  })
  open.value = false
}

defineExpose<TaskFieldRef>({
  open: () => {
    open.value = true
  },
  close: () => {
    open.value = false
  },
  delete: () => {
    onClear()
  },
})
</script>
