<template>
  <div
    class="h-full w-full"
  >
    <DeferredPopover
      ref="popoverRef"
      :ui="{
        width: 'min-w-52 w-full max-w-[280px]',
        wrapper: 'h-full',
      }"
      :trigger-class="triggerClass"
      v-bind="$attrs"
      :disabled="disabled"
      @update:open="$emit('update:open', $event)"
    >
      <template #content>
        <PreviewPopoverContentPeople
          :popover-ref="popoverRef"
          :selected-values="selectedValues"
          :field-id="fieldId"
          :label="label"
          @select="$emit('select', $event)"
          @remove="$emit('remove', $event)"
          @close="popoverRef.close()"
        />
      </template>
      <slot />
    </DeferredPopover>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  disabled?: boolean
  selectedValues: string[]
  label: string
  fieldId: string
  triggerClass?: (active: boolean) => string[]
}>()

defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'select', value: string, fieldId?: string): void
  (e: 'remove', value: string, fieldId?: string): void
}>()

const popoverRef = ref()
</script>
