<template>
  <FilterSelect
    ref="filterSelectRef"
    :title="label"
    placeholder="Search by name or email"
    :classes="{
      list: 'max-h-[22.25rem] pb-2',
      item: '!py-1',
    }"
    :options="boardMembers"
    :data-test="{
      container: 'assignee-popover',
    }"
    :close-on-selected="false"
    :search-attributes="['fullName', 'email']"
    @change="onSelect"
    @close="$emit('close')"
  >
    <template #item="{ option }">
      <FieldItem
        :checked="checkedIds.includes(option.id)"
        :indeterminate="indeterminateIds.includes(option.id)"
        :avatar="option"
        :text="option.fullName"
        @change="onSelect(option)"
      />
    </template>
  </FilterSelect>
</template>

<script lang="ts" setup>
import { cloneDeep } from 'lodash-es'
import type { BaseUser } from '#auth/types'

const props = defineProps<{
  label?: string
  selectedValues: string[]
  fieldId: string
}>()

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'close'): void
  (e: 'select', value: string, fieldId?: string): void
  (e: 'remove', value: string, fieldId?: string): void
}>()

const { auth } = storeToRefs(useAuthStore())
const { boardData } = useBoardSharedState()
const { getCheckedIds, getIndeterminateIds } = useFieldMultipleSelect()

const boardMembers = computed(() => {
  const users = cloneDeep(boardData.value?.users || [])
  const selectedUsers = users.filter((user) =>
    props.selectedValues.includes(user.id)
  )
  const otherUsers = users.filter(
    (user) => !props.selectedValues.includes(user.id)
  )
  selectedUsers.sort(
    (a, b) =>
      props.selectedValues.indexOf(a.id) - props.selectedValues.indexOf(b.id)
  )
  if (props.selectedValues.includes(auth.value?.id)) {
    bringMemberToTop(selectedUsers as BaseUser[], auth.value?.id)
  }

  const sortedUsers = [...selectedUsers, ...otherUsers]
  return sortedUsers as BaseUser[]
})

const bringMemberToTop = (users: BaseUser[], id: string) => {
  const userIndex = users.findIndex((user) => user?.id === id)
  if (userIndex !== -1) {
    const cloneMember = users[userIndex]
    users.splice(userIndex, 1)
    users.unshift(cloneMember)
  }
}

const onSelect = (user: BaseUser) => {
  const value = user.id

  if (
    indeterminateIds.value?.includes(value) ||
    !props.selectedValues?.includes(value)
  ) {
    return emit('select', value, props.fieldId)
  }

  return emit('remove', value, props.fieldId)
}

const checkedIds = computed(() => {
  return getCheckedIds({
    fieldId: props.fieldId,
    options: boardMembers.value,
    selectedValues: props.selectedValues,
    key: 'id',
  })
})

const indeterminateIds = computed(() => {
  return getIndeterminateIds({
    fieldId: props.fieldId,
    options: boardMembers.value,
    key: 'id',
  })
})
</script>
